<template>
  <div id="note-comp">
    <div class="mb-1">
      <i
        class="box-icon box-icon--effect bx bx-user-circle"
        :class="`${showMyNotes ? 'box-icon--active' : ''}`"
        name="user-circle"
        @click="toggleShow"
        :title="showMyNotes ? 'Show All Notes' : 'Show My Notes'"
      />
    </div>
    <div class="topbar">
      <h3 class="topbar--recent" v-if="notedItems && notedItems.length > 0">
        {{ $t("comp_notedItems--title") }}
      </h3>
      <div class="extra-btns">
        <div class="extra-toggle d-flex">
          <div v-if="notesPageNo !== 0" class="btn">
            <i
              class="
                custom-box-css
                d-flex
                box-icon box-icon--effect
                bx bx-chevrons-left
              "
              name="chevrons-left"
              title="Preivous Page"
              @click="previousNote"
            ></i>
          </div>
        </div>
        <div class="btn full-screen-notes">
          <i
            class="
              fullcreentoggle
              custom-box-css
              d-flex
              box-icon box-icon--effect
              bx bx-search-alt-2
            "
            name="search-alt-2"
            title="Search Notes"
            @click="toggleSearch()"
          ></i>
        </div>
        <div class="add-to-note btn">
          <i
            class="
              custom-box-css
              d-flex
              box-icon box-icon--effect
              bx bx-notepad
            "
            name="notepad"
            @click="addNoteFormToggle"
            title="Note Editor"
          ></i>
        </div>
        <div class="btn refresh-notes">
          <i
            class="
              forceRefresh
              custom-box-css
              d-flex
              box-icon box-icon--effect
              bx bx-refresh
            "
            name="refresh"
            title="Refresh"
            @click="forceRefresh()"
          ></i>
        </div>
        <div v-if="notesLeft !== 0" class="btn">
          <i
            class="
              custom-box-css
              d-flex
              box-icon box-icon--effect
              bx bx-chevrons-right
            "
            name="chevrons-right"
            :title="`${notesLeft} notes left`"
            @click="nextNote"
          ></i>
        </div>
      </div>
    </div>
    <form
      v-if="searchToggle"
      class="d-flex mx-1 mt-2"
      v-on:submit.prevent="notesFilter()"
    >
      <input
        class="input-wrap form-input form-control"
        type="search"
        v-model="noteSearch"
        placeholder="Search Notes by Username, Search Phrase"
      />
    </form>
    <div v-if="notedItems && notedItems.length > 0">
      <transition name="fade">
        <div v-show="isAddNote" class="addNote mb-3">
          <!-- boostrap 5 form with textarea and primary button -->
          <form @submit.prevent="createNote" class="form-inline" role="form">
            <div class="form-group">
              <!-- text area with Add to note placeholder -->
              <Editor :onContentChange="onContentChange" />
            </div>
            <div class="d-grid gap-2">
              <button class="ed-btn__blim ed-btn__sm" type="submit">
                Add note
              </button>
            </div>
          </form>
        </div>
      </transition>

      <div class="allNoteItems mb-3 mt-3" :style="heightSettings">
        <NoteDetails
          v-bind:noteId="item.id"
            v-bind:noteTitle="item.noteTitle"
          v-bind:note="item"
          v-bind:goalId="item.goal || null"
          v-bind:index="index"
          v-for="(item, index) in notedItems"
          :key="item.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import 'mavon-editor/dist/css/index.css';
import { mapActions } from 'vuex';
import store from '@/store';
import moment from 'moment';
import NoteDetails from '@/common/widgets/NotesLibraryWidget/NoteDetails.vue';
import mavenEditorConfigs from '@/config/mavenEditor';
import Editor from '@/common/components/Editor.vue';

export default {
  async mounted() {
    await this.fetchNotesData({
      user: this.selectedCategories,
    });
  },
  components: { NoteDetails, Editor },
  name: 'NotesLibrary',
  data() {
    return {
      showMyNotes: true,
      searchToggle: false,
      notesPageNo: 0,
      noteSearch: '',
      selectedCategories: '@me',
      categories: ['@all', '@me'],
      notedItems: [],
      heightSettings: {
        'max-height': '100vh',
      },
      isAddNote: false,
      customNote: '',
      mavonToolbar: mavenEditorConfigs.mavonToolbar,
    };
  },

  computed: {
    stateNotes() {
      return this.$store.state.notes.data;
    },
    notesLeft() {
      return this.$store.state.notes.left;
    },
  },

  sockets: {
    addToEditor(data) {
      if (data.actor.username === this.$store.state.auth.user.username) {
        if (this.isAddNote) {
          if (data.value.word.trim().startsWith('https://www.youtube.com/embed')) {
            this.customNote += `<iframe
          src="${data.value.word.trim()}"
          frameborder="0"
          allowfullscreen
        ></iframe> \n\n`;
          } else {
            this.customNote += data.value.word;
          }
        }
      }
    },
    addUserNote(data) {
      // if (this.selectedCategories === '@all') {
      if (data.actor.username === this.$store.state.auth.user.username) {
        this.$store.dispatch('addNoteToNotesLibrary', data);
      }
    },

    deleteNoteUser(id) {
      if (this.selectedCategories === '@all') {
        console.log('DeleteNote Executed');
        this.$store.dispatch('deleteNoteFromLibrary', id);
      }
    },

    updateNoteUser(data) {
      if (this.selectedCategories === '@all') {
        this.$store.dispatch('updateNoteInLibrary', data);
      }
    },
  }, //   newNotification(message) {
  //     const msg = JSON.parse(message);
  //     if (msg.verb.display.en === 'NOTED') {
  //       this.forceRefresh();
  //     }

  //     if (msg.verb.display.en === 'DELETE_NOTE') {
  //       this.forceRefresh();
  //     }

  //     if (msg.verb.display.en === 'UPDATE_NOTE') {
  //       this.forceRefresh();
  //     }
  //   },
  // },

  watch: {
    stateNotes(val) {
      this.notedItems = [...val];
    },
    async noteSearch(val) {
      if (val === '') {
        this.notesPageNo = 0;
        await this.notesFetch({
          user: this.selectedCategories,
          type: 'next',
          pageNo: this.notesPageNo,
        });
      }
    },
    selectedCategories(user) {
      this.fetchNotesData({
        user,
      });
      this.forceRefresh();
    },
  },

  methods: {
    ...mapActions({
      notesFetch: 'notesFetch',
      addToNote: 'addToNote',
    }),
    async fetchNotesData(payload) {
      const res = await this.notesFetch(payload);
      this.notedItems = res;
    },
    toggleShow() {
      this.showMyNotes = !this.showMyNotes;
      if (this.showMyNotes) {
        this.selectedCategories = '@me';
      } else {
        this.selectedCategories = '@all';
      }
    },
    imgAdd(pos, $file) {
      const formdata = new FormData();
      formdata.append('upl', $file);
      axios({
        url: `${process.env.VUE_APP_AXIOS_URI}/custom/scr/upload`,
        method: 'post',
        data: formdata,
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((url) => {
        this.$refs.md.$img2Url(pos, url.data.Location);
      });
    },
    toggleSearch() {
      this.searchToggle = !this.searchToggle;
    },

    notesFilter() {
      this.notesPageNo = 0;
      const payload = {
        user: this.selectedCategories,
        type: 'search',
        searchWord: this.noteSearch,
        pageNo: this.notesPageNo,
      };
      this.notesFetch(payload);
      const eventPayload = {
        verb: 'NOTE_SEARCH',
        value: {
          searchWord: payload.searchWord,
        },
      };
      store.dispatch('eventToEventStore', eventPayload, { root: true });
    },

    async nextNote() {
      // eslint-disable-next-line no-plusplus

      if (this.noteSearch === '') {
        const payload = {
          type: 'next',
          pageNo: this.notesPageNo + 1,
          user: this.selectedCategories,
        };
        await this.notesFetch(payload);
      } else {
        const payload = {
          type: 'search',
          searchWord: this.noteSearch,
          pageNo: this.notesPageNo + 1,
          user: this.selectedCategories,
        };
        await this.notesFetch(payload);
      }
      this.notesPageNo += 1; // ? increase page No. by 1
    },

    async previousNote() {
      // eslint-disable-next-line no-plusplus
      if (this.noteSearch === '') {
        const payload = {
          type: 'next',
          pageNo: this.notesPageNo - 1,
          user: this.selectedCategories,
        };
        await this.notesFetch(payload);
      } else {
        const payload = {
          type: 'search',
          searchWord: this.noteSearch,
          pageNo: this.notesPageNo - 1,
          user: this.selectedCategories,
        };
        await this.notesFetch(payload);
      }
      this.notesPageNo -= 1;
    },

    // dateWithinOneWeek(date) {
    //   if (date) return moment(date).isSameOrAfter(moment().subtract(2, 'week'));
    //   return false;
    // },
    toggleNotedHeight() {
      this.heightSettings['max-height'] = this.heightSettings['max-height'] === '40vh' ? '100vh' : '40vh';
    },
    addNoteFormToggle() {
      this.isAddNote = !this.isAddNote;
    },
    async forceRefresh() {
      await this.notesFetch({
        user: this.selectedCategories,
        type: 'next',
        pageNo: 0,
      });

      this.notesPageNo = 0;
    },
    // filterByUser(user) {
    //   if (user === 'All') {
    //     this.notedItems = this.$store.state.notes.reverse();
    //   } else {
    //     this.notedItems = this.$store.state.notes
    //       .filter((item) => item.actor.username === user)
    //       .reverse();
    //   }
    // },
    async createNote() {
      const response = await this.$store.dispatch('parseAndQueryFromOpenAI', {
        text: this.customNote,
        splitter: 'eddie',
        callback: async (query) => {
          const detail = await this.$store.dispatch('fetchFromOpenAI', query);
          return detail;
        },
      });

      if (response) this.customNote = response;
      const value = {
        note: this.customNote,
        noteUrl: 'thoughtjumper-client.netlify.app/',
        type: 'note',
        timestamp: moment().format(),
      };

      this.addToNote(value).then((data) => {
        this.customNote = '';
        this.$socket.emit('add_note', data);
      });
    },
    onContentChange(val) {
      this.customNote = val;
    },
  },
  async created() {
    await this.notesFetch({
      type: 'next',
      pageNo: this.notesPageNo,
      user: this.selectedCategories,
    });
    const { notes } = this.$store.state;
    this.notedItems = [...notes];
  },
  // beforeDestroy() {
  //   this.unsubscribe();
  // },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/variables";
// @import 'mavon-editor/dist/css/index.css';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.displays {
  display: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.topbar {
  display: flex;
  justify-content: space-between;

  &--recent {
    font-size: 1.5rem;
  }
}
.custom-box-css {
  transition: all 0.5s;
  &:hover {
    cursor: pointer;
    transition: all 0.5s;
  }
}
#note-comp {
  // padding: 20px 20px;
  // height: auto;
  position: relative;
  height: auto;
  // overflow: auto;
}
.allNoteItems {
  display: flex;
  flex-direction: column;
  // margin-top: 20px;
  overflow: scroll;
  overflow-x: hidden;
  .noteItem.list-group-item {
    // margin-bottom: 5;
    scroll-snap-align: end;

    .noteItem-video {
      width: 100%;
      height: auto;
    }

    .noteItem-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .noteItem-asset {
        max-height: 100%;
        max-width: 100%;
        // css fit image in div

        img {
          max-width: 100%;
          max-height: 100%;
        }
        iframe {
          height: 315px;
          width: 560px;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .deleteBtn {
        width: 40px;
        color: red;
      }
    }
    .noteItem-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .noteItem-header-date {
        text-align: right;
        display: inline-block;
      }
    }
  }

  &::-webkit-scrollbar-track {
    background: $lightGray;
    // border-left: 5px solid $accentYellow;
    border-left: none;
  }
  &::-webkit-scrollbar {
    width: 0.3rem;
    height: 0px;
  }
}
iframe {
  min-height: 20vh;
  width: 100%;
}
.extra-btns {
  display: flex;
  gap: 0.2em;
}
#note-input {
  z-index: 1;
}
</style>
